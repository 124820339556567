.student-list {
  padding: 20px 0;

  &__circular {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__grid {
    margin-top: 6px;

    &__pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
    }
  }
}
