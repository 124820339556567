@import "../../../style/colors.module.scss";

.class-container {
  margin-top: 54px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);

  &__aligner {
    max-width: 800px;
    width: 100%;
  }

  &__main-card {
    background-color: #{getColorVarValue("primary")};
    padding: 32px;
    border-radius: 4px;

    &__up-title {
      color: #{getColorVarValue("light_gray")};
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 1.5;
      text-transform: uppercase;
    }

    &__title {
      color: #{getColorVarValue("white")};
      font-size: 28px;
      line-height: 1.25;
      font-weight: 800;
      margin-bottom: 24px;
    }

    &__meta {
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      gap: 16px;

      &__level {
        color: #{getColorVarValue("white")};
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &__icon {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }

        &__active {
          background-color: #{getColorVarValue("secondary")};
        }

        &__expired {
          background-color: #{getColorVarValue("danger")};
        }
      }

      &__chip {
        background-color: #{getColorVarValue("dark_blue")};
        border-radius: 2px;
        color: #{getColorVarValue("white")};
        padding: 2px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        font-size: 14px;
      }
    }
  }

  &__description {
    margin-top: 24px;
    margin-bottom: 36px;

    &__title {
      font-size: 16px;
      font-weight: 800;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      word-break: break-word;
      margin: 14px 0;
    }
  }

  &__content {
    background-color: #{getColorVarValue("white")};
    border-color: rgba(48, 57, 105, 0.15);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 16px;
    margin-bottom: 16px;
    transition: box-shadow 600ms cubic-bezier(0.1, 0.8, 0.2, 1) 0s, transform 600ms cubic-bezier(0.1, 0.8, 0.2, 1) 0s;
  }
}
