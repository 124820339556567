@import "../../style/colors.module.scss";

.signin {
  background-color: #{getColorVarValue("primary")};
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    background-color: #{getColorVarValue("white")};
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;

    form {
      width: 100%;
    }

    label {
      display: block;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}
