@import "../../style/colors.module.scss";

.hw-detail-header {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  gap: 8px;

  &__home-btn {
    z-index: 1;
  }

  &__breadcrumb {
    z-index: 1;
    font-size: 14px;
    user-select: none;
  }

  &__navigator {
    z-index: 0;
    height: 36px;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: calc(100% - 22px);
    justify-content: center;

    &__prev,
    &__next {
      width: 36px;
      border-top: 2px solid rgba(5, 25, 45, 0.6);
      border-bottom: 2px solid rgba(5, 25, 45, 0.6);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #{getColorVarValue("light_white")};
      }
    }

    &__prev {
      border-left: 2px solid rgba(5, 25, 45, 0.6);
      border-radius: 4px 0 0 4px;
    }

    &__next {
      border-right: 2px solid rgba(5, 25, 45, 0.6);
      border-radius: 0 4px 4px 0;
    }

    &__outline {
      border: 2px solid rgba(5, 25, 45, 0.6);
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      padding: 0 14px;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        background-color: #{getColorVarValue("light_white")};
      }
    }
  }

  &__level {
    margin-left: auto;
    font-size: 14px;
    z-index: 1;
    user-select: none;
    margin-right: 20px;

    &__icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #{getColorVarValue("secondary")};
      margin-right: 6px;
    }
  }
}
