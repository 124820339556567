.new-student {
  &__input {
    margin-top: 12px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
