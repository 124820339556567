@import "../../style/colors.module.scss";

.hw-section-container {
  background-color: #{getColorVarValue("white")};
  border-color: rgba(48, 57, 105, 0.15);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 600ms cubic-bezier(0.1, 0.8, 0.2, 1) 0s, transform 600ms cubic-bezier(0.1, 0.8, 0.2, 1) 0s;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;

    &__index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #{getColorVarValue("light_white")};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    &__text {
      line-height: 1.25;
      font-weight: 800;
    }
  }

  &__description {
    margin-bottom: 24px;
    line-height: 1.5;
    font-size: 14px;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #{getColorVarValue("light_white")};
    margin-bottom: 16px;
  }
}
